import config from '../config';
import { CONTACT_EMAIL } from './constants';

export const APP_LINKS = {
  dashboard: '/dashboard',
  help: '/help',
  profile: '/profile',
  loans: '/loans',
  fundraising: '/fundraising',
  founders: '/founders',
  startups: '/startups',
  payments: '/payments',
  activity: '/activity',
  signIn: '/sign-in',
  nextPayment: '/payments/next-payments',
  prepayLoans: '/payments/prepay-loans',
  bankStatements: '/payments/bank-statements',
  addFunds: '/payments/add-funds',
  addFundsResult: '/payments/add-funds/result',
  withdraw: '/payments/withdraw',
  bankAccounts: '/payments/bank-accounts',
  bankAccountsCreate: '/payments/bank-accounts/new',
  bankAccountsEdit: id => `/payments/bank-accounts/${id}`,
  helpLoanTerms: version => `/help/loan-terms${version ? `/${version}` : ''}`,
  unsubordinatedFinancialAgreement:
    config.mainPromoDomain + '/docs/scramble_financing_agreement_unsubordinated_loans.pdf',
  subordinatedFinancialAgreement:
    config.mainPromoDomain + '/docs/scramble_financing_agreement_subordinated_loans.pdf',
  docAnnexToSubordinatedLoansAgreement:
    config.mainPromoDomain + '/docs/scramble_annex_to_subordinated_loans_agreement.pdf',
  docLoanSeveranceAgreement: config.mainPromoDomain + '/docs/loan_severance_agreement.pdf',
  docAnnexToUnSubordinatedLoansAgreement:
    config.mainPromoDomain + '/docs/scramble_annex_to_unsubordinated_loans_agreement.pdf',
  docAssignmentAgreementGeneralTerms:
    config.mainPromoDomain + '/docs/Scramble_Assignment_Agreement_General_Terms.pdf',
  suretyAgreement: config.mainPromoDomain + '/docs/Surety_agreement.pdf',
  founderPromoDomain: config.founderPromoDomain,
  howItWorks: config.investorPromoDomain + '/how-it-works',
  docPrivacyAndPolicy: config.mainPromoDomain + '/docs/Privacy_policy.pdf',
  docTermsOfUse: config.mainPromoDomain + '/docs/Terms_of_use.pdf',
  docGuideForFounders: config.mainPromoDomain + '/docs/guide_for_founders.pdf',
  contactEmail: `mailto:${CONTACT_EMAIL}`,
  startupDetail: slug => `/startups/${slug}`,
  founderDetail: id => `/founders/${id}`,
};
