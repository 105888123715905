import React from 'react';
import classnames from 'classnames';
import config from '../../../../config';
import { MyLink } from '../../../../components/MyLink';
import Dropdown from '../../../../components/Dropdown';

export const FinancingAgreements = ({ fundraisingId, data }) => {
  if (!data) return null;

  return (
    <div className={classnames('loan-info-contracts', data?.insurance_strategy)}>
      <Dropdown
        label={
          <div className="loan-info-contracts__header">
            <h2 className="f-26 f-500">Financing agreements</h2>
            {/*<RoundLink*/}
            {/*  label="Download all files (pdf)"*/}
            {/*  path={`${config.apiURL}fundraisings/${data?.id}/agreements/`}*/}
            {/*  className="loan-info-contracts__download"*/}
            {/*  fillBackground*/}
            {/*  isExternal*/}
            {/*/>*/}
          </div>
        }
      >
        {fundraisingId >= 92 ? (
          <div className="loan-info__empty-message f-16">
            Files are being processed, this may take up to 24 hours.
          </div>
        ) : data ? (
          <div className="loan-info-contracts__table">
            <div className="loan-info-contracts__thead">
              <div className="loan-info-contracts__thead-col">Loan type</div>
              <div className="loan-info-contracts__thead-col">Loan number</div>
              {/*<div className="loan-info-contracts__thead-col">Financing agreement</div>*/}
              {/*<div className="loan-info-contracts__thead-col">Surety agreement</div>*/}
              <div className="loan-info-contracts__thead-col">Download agreements</div>
            </div>
            <div className="loan-info-contracts__tbody">
              {data.agreements.map(agreement => (
                <div className="loan-info-contracts__tbody-row" key={agreement.id}>
                  <div className="loan-info-contracts__tbody-col">{agreement.risk_group_title}</div>
                  <div className="loan-info-contracts__tbody-col">{`Loan #${agreement.id}`}</div>
                  {/*<div className="loan-info-contracts__tbody-col">*/}
                  {/*  <MyLink*/}
                  {/*    href={`${config.apiURL}loans/${agreement.id}/agreement/`}*/}
                  {/*    isExternal*/}
                  {/*  >*/}
                  {/*    Download*/}
                  {/*  </MyLink>*/}
                  {/*</div>*/}
                  {/*<div className="loan-info-contracts__tbody-col">*/}
                  {/*  <MyLink*/}
                  {/*    href={`${config.apiURL}loans/${agreement.id}/surety_agreement/`}*/}
                  {/*    isExternal*/}
                  {/*  >*/}
                  {/*    Download*/}
                  {/*  </MyLink>*/}
                  {/*</div>*/}
                  <div className="loan-info-contracts__tbody-col">
                    <MyLink
                      href={`${config.apiURL}founders/loans/${agreement.id}/assignments/`}
                      isExternal
                    >
                      Download
                    </MyLink>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="loan-info__empty-message f-16">No Financing agreements yet.</div>
        )}
      </Dropdown>
    </div>
  );
};
