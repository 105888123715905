import React from 'react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import * as Yup from 'yup';
import { MoneyField } from '../../UI/MoneyField';
import { RoundButton } from '../../UI/RoundButton';
import NotificationBlock from '../../../containers/NotificationBlock';
import { prettyFloatMoney, round } from '../../../helpers/utils';
import { RoundLink } from '../../UI/RoundLink';
import { ROLES, USER_STATUSES } from '../../../helpers/constants';
import Checkbox from '../../UI/Checkbox';
import { MyLink } from '../../MyLink';
import { APP_LINKS } from '../../../helpers/links';
import { NOTIFICATIONS } from '../../Notification/list';
import { isVerifiedPhoneNumber } from '../../../helpers/user';
import './index.scss';

const VALIDATION_SCHEMA = (min, max) =>
  Yup.object().shape({
    canApply: Yup.bool()
      .oneOf([true], 'Your available cash is less then minimum required')
      .required(''),
    goal: Yup.number()
      .min(min, `Minimal amount ${prettyFloatMoney(min)}`)
      .max(max, `Maximum amount ${prettyFloatMoney(max)}`)
      .required('Goal is required'),
    has_accepted_terms: Yup.bool().oneOf([true], 'Accept terms'),
  });

const DEFAULT_MIN = 25;
const DEFAULT_MAX = 20000;
const DEFAULT_INPUT_LIMIT = 100000000000000000000;

// const calculateFee = (goal, fee) => {
//   const initial = Number(goal);
//   return initial && fee ? round((initial * fee) / 100, 2) : 0;
// };

const FundraisingGoalForm = ({
  status,
  user,
  onSubmit,
  disabled,
  missingInfo,
  onReset,
  coFounders,
}) => {
  const profile = useSelector(state => state.profileStore.profile.data);
  const missingInfoExist = !!Object.keys(missingInfo).length;
  const notifications = [];

  missingInfoExist &&
    !missingInfo.has_contact_details &&
    notifications.push(idx => (
      <li key={idx}>
        Provide your contact details to start fundraising{' '}
        <Link to="/profile?r=contacts" style={{ color: '#9E98A3' }}>
          Provide details →
        </Link>
      </li>
    ));

  missingInfoExist &&
    !missingInfo.has_company_info &&
    notifications.push(idx => (
      <li key={idx}>
        Provide your company details to start fundraising{' '}
        <Link to="/profile?r=company" style={{ color: '#9E98A3' }}>
          Provide details →
        </Link>
      </li>
    ));

  missingInfoExist &&
    !missingInfo.is_verified &&
    notifications.push(idx => (
      <li key={idx}>
        Verify your identity to start fundraising.{' '}
        <Link to="/profile?r=veriff" style={{ color: '#9E98A3' }}>
          Start verification process →
        </Link>
      </li>
    ));

  missingInfoExist &&
    missingInfo.has_contact_details &&
    missingInfo.has_company_info &&
    missingInfo.is_verified &&
    missingInfo.status !== USER_STATUSES.verified &&
    notifications.push(idx => (
      <li key={idx}>
        We are currently verifying your profile information, it may take up to 2 business day
      </li>
    ));

  if (missingInfoExist && missingInfo.status === USER_STATUSES.verified && status && !disabled) {
    status.goal
      ? notifications.push(NOTIFICATIONS.you_can_change_fundraising_goal_until(status.start_date))
      : notifications.push(NOTIFICATIONS.set_fundraising_goal_until(status.start_date));
  }

  coFounders?.length &&
    user.role !== ROLES.founder &&
    notifications.push(idx => (
      <li key={idx}>
        Configuring fundraising settings is available only to the company administrator.
      </li>
    ));

  profile &&
    !isVerifiedPhoneNumber(profile.user) &&
    notifications.push(NOTIFICATIONS.verify_phone());

  const initialGoal = status
    ? status.goal
      ? status.goal.toString()
      : status.max_goal < status.initial_limit
      ? status.max_goal.toString()
      : status.initial_limit.toString()
    : '';

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, formikBag) => values.canApply && onSubmit(values, formikBag)}
      validationSchema={VALIDATION_SCHEMA(
        (status && status.min_goal) || DEFAULT_MIN,
        (status && status.max_goal) || DEFAULT_MAX
      )}
      initialValues={{
        goal: initialGoal,
        canApply: status && (status.max_goal === 0 || status.max_goal > status.min_goal),
        has_accepted_terms: false,
      }}
    >
      {({ values, touched, errors, setValues, handleChange, setFieldValue, handleSubmit }) => (
        <form onSubmit={handleSubmit} className="fundraising-goal-form">
          <h1 className="f-42 f-500">
            {values.canApply
              ? status.goal
                ? 'Fundraising goal saved!'
                : 'Set fundraising goal'
              : 'Your fundraising goal could not be approved due to your current debts. If you would like to fundraise cash, pay off all your debts first.'}
          </h1>
          {!values.canApply ? (
            <RoundLink
              label="Go to payment"
              path={APP_LINKS.payments}
              className="fundraising-goal-form__payment"
            />
          ) : (
            <>
              <NotificationBlock
                notifications={notifications}
                className="fundraising-goal-form__notifications"
              />

              <div className={classnames(disabled && 'fundraising-goal-form__disabled')}>
                <h2 className="fundraising-goal-form__subtitle f-26 f-500">
                  How much funds do you want to raise?
                </h2>
                <MoneyField
                  name="goal"
                  label="Fundraising goal"
                  onChange={(_, goal) =>
                    setValues({
                      ...values,
                      goal,
                      has_accepted_terms: false,
                    })
                  }
                  value={values.goal}
                  onClear={values.canApply ? () => setFieldValue('goal', '') : null}
                  disabled={disabled || !values.canApply}
                  max={(status && status.max_goal + DEFAULT_INPUT_LIMIT) || DEFAULT_INPUT_LIMIT}
                  error={errors.goal}
                  className="fundraising-goal-form__goal"
                />
                {/*<p className="fundraising-goal-form__goal-desc f-16">*/}
                {/*  Keep in mind: you will actually get a little less, the fee is{' '}*/}
                {/*  {prettyFloatMoney(calculateFee(values.goal, FLAT_FEE))}*/}
                {/*</p>*/}
              </div>

              <div className="fundraising-goal-form__checkboxes">
                <Checkbox
                  name="has_accepted_terms"
                  disabled={disabled || !values.canApply}
                  isChecked={values.has_accepted_terms}
                  onChange={handleChange}
                  error={touched.has_accepted_terms && errors.has_accepted_terms}
                  render={() => (
                    <span
                      className={classnames(
                        'f-16',
                        touched.has_accepted_terms && errors.has_accepted_terms && 'error'
                      )}
                    >
                      I have read the terms and conditions of the{' '}
                      <MyLink href={APP_LINKS.docTermsOfUse} isExternal>
                        Terms of Use
                      </MyLink>
                      , the{' '}
                      <MyLink href={APP_LINKS.docLoanSeveranceAgreement} isExternal>
                        Severance Agreement
                      </MyLink>
                      , the{' '}
                      <MyLink href={APP_LINKS.docAnnexToUnSubordinatedLoansAgreement} isExternal>
                        Annex to Financing Agreement
                      </MyLink>
                      , the{' '}
                      <MyLink href={APP_LINKS.docAnnexToSubordinatedLoansAgreement} isExternal>
                        Annex to Subordinated Financing Agreement
                      </MyLink>
                      , the{' '}
                      <MyLink href={APP_LINKS.docAssignmentAgreementGeneralTerms} isExternal>
                        Assignment Agreement
                      </MyLink>
                      , the{' '}
                      <MyLink href={APP_LINKS.suretyAgreement} isExternal>
                        Surety Agreement
                      </MyLink>
                      , and I agree to conclude them with the Investors as a result of the current
                      fundraising round.
                    </span>
                  )}
                />

                {/*{status.is_resold_loans ? (*/}
                {/*  <Checkbox*/}
                {/*    name="has_accepted_terms"*/}
                {/*    disabled={disabled || !values.canApply}*/}
                {/*    isChecked={values.has_accepted_terms}*/}
                {/*    onChange={handleChange}*/}
                {/*    error={touched.has_accepted_terms && errors.has_accepted_terms}*/}
                {/*    render={() => (*/}
                {/*      <span*/}
                {/*        className={classnames(*/}
                {/*          'f-16',*/}
                {/*          touched.has_accepted_terms && errors.has_accepted_terms && 'error'*/}
                {/*        )}*/}
                {/*      >*/}
                {/*        I have read the terms and conditions of the{' '}*/}
                {/*        <MyLink href={APP_LINKS.docTermsOfUse} isExternal>*/}
                {/*          Terms of Use*/}
                {/*        </MyLink>*/}
                {/*        , the{' '}*/}
                {/*        <MyLink href={APP_LINKS.docAnnexToUnSubordinatedLoansAgreement} isExternal>*/}
                {/*          Annex to Financing Agreement*/}
                {/*        </MyLink>*/}
                {/*        , the{' '}*/}
                {/*        <MyLink href={APP_LINKS.docAnnexToSubordinatedLoansAgreement} isExternal>*/}
                {/*          Annex to Subordinated Financing Agreement*/}
                {/*        </MyLink>{' '}*/}
                {/*        and the{' '}*/}
                {/*        <MyLink href={APP_LINKS.docAssignmentAgreementGeneralTerms} isExternal>*/}
                {/*          Assignment Agreement*/}
                {/*        </MyLink>*/}
                {/*        , and I agree to conclude them with the Investors as a result of the current*/}
                {/*        Fundraising round.*/}
                {/*      </span>*/}
                {/*    )}*/}
                {/*  />*/}
                {/*) : (*/}
                {/*  <Checkbox*/}
                {/*    name="has_accepted_terms"*/}
                {/*    disabled={disabled || !values.canApply}*/}
                {/*    isChecked={values.has_accepted_terms}*/}
                {/*    onChange={handleChange}*/}
                {/*    error={touched.has_accepted_terms && errors.has_accepted_terms}*/}
                {/*    render={() => (*/}
                {/*      <span*/}
                {/*        className={classnames(*/}
                {/*          'f-16',*/}
                {/*          touched.has_accepted_terms && errors.has_accepted_terms && 'error'*/}
                {/*        )}*/}
                {/*      >*/}
                {/*        I have read the terms and conditions of the{' '}*/}
                {/*        <MyLink href={APP_LINKS.docTermsOfUse} isExternal>*/}
                {/*          Terms of Use*/}
                {/*        </MyLink>*/}
                {/*        , the{' '}*/}
                {/*        <MyLink href={APP_LINKS.unsubordinatedFinancialAgreement} isExternal>*/}
                {/*          Financing Agreement*/}
                {/*        </MyLink>*/}
                {/*        , the{' '}*/}
                {/*        <MyLink href={APP_LINKS.subordinatedFinancialAgreement} isExternal>*/}
                {/*          Subordinated Financing Agreement*/}
                {/*        </MyLink>*/}
                {/*        , the{' '}*/}
                {/*        <MyLink href={APP_LINKS.suretyAgreement} isExternal>*/}
                {/*          Surety Agreement*/}
                {/*        </MyLink>*/}
                {/*        , and I agree to conclude them with the Investors as a result of the current*/}
                {/*        Fundraising round.*/}
                {/*      </span>*/}
                {/*    )}*/}
                {/*  />*/}
                {/*)}*/}
              </div>

              <div className="fundraising-goal-form__buttons">
                {!!status?.is_participating && (
                  <RoundButton
                    type="button"
                    label="Reset fundraising goal"
                    className="fundraising-goal-form__reset"
                    onClick={onReset}
                    disabled={disabled || !values.canApply}
                  />
                )}
                <RoundButton
                  type="submit"
                  label={status?.goal ? 'Save changes' : 'Set fundraising goal'}
                  className="fundraising-goal-form__submit"
                  disabled={disabled || !values.canApply}
                  onSubmit={handleSubmit}
                  fillBackground
                  fullWidth
                />
              </div>
            </>
          )}
        </form>
      )}
    </Formik>
  );
};

export default FundraisingGoalForm;
