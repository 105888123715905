import * as React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { RoundButton } from '../../UI/RoundButton';
import { InputTextField } from '../../UI/InputTextField';
import { PhoneNumberField } from '../../UI/PhoneNumberField';
import CountrySelectField from '../../UI/CountrySelectField';
import { BasicUploadField } from '../../../kit/form/BasicUploadField';
import { PDF_ONLY } from '../../../helpers/common';
import './index.scss';

const VALIDATION_SCHEMA = Yup.object({
  country: Yup.string().required('Country is required'),
  city: Yup.string().required('City is required'),
  address: Yup.string().max(255, 'Address exceeds limit of 255').required('Address is required'),
  postal_code: Yup.string().min(4, 'Invalid postal code').max(10, 'Invalid postal code'),
  phone: Yup.number().required('Phone number is required'),
  is_valid_phone: Yup.bool().oneOf([true], 'Invalid phone number').required(),
  proof_of_residential_address: Yup.array()
    .min(1, 'Proof of founder’s residential address required field')
    .required('Proof of founder’s residential address required field'),
});

class ProfileContactsForm extends React.Component {
  getInitialValues = (user, location) => ({
    country: (user && user.country) || (location && location.country) || '',
    country_code: (user && user.country_code) || (location && location.countryCode) || '',
    city: (user && user.city) || (location && location.city) || '',
    address: (user && user.address) || '',
    postal_code: (user && user.postal_code) || '',
    phone: (user && user.phone) || '',
    is_valid_phone: true,
    proof_of_residential_address: user?.proof_of_residential_address
      ? [user.proof_of_residential_address]
      : [],
  });

  render() {
    const { userLocation, user, onSubmit, onCancel } = this.props;
    return (
      <Formik
        enableReinitialize
        initialValues={this.getInitialValues(user, userLocation)}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      >
        {({
          values,
          setFieldValue,
          setFieldError,
          errors,
          touched,
          handleChange,
          handleSubmit,
        }) => (
          <form className="profile-personal-form" onSubmit={handleSubmit}>
            <h1 className="profile-personal-form__title f-32 f-500">Edit contact information</h1>
            <div className="profile-personal-form__container">
              <CountrySelectField
                label="Residence country *"
                name="country"
                value={values.country_code}
                onChange={(country, code) => {
                  setFieldValue('country', country);
                  setFieldValue('country_code', code);
                }}
                error={errors.country && touched.country && errors.country}
                className="profile-personal-form__field"
              />
              <PhoneNumberField
                label="Phone number *"
                name="phone"
                value={values.phone}
                countryCode={values.country_code}
                onChange={phone => setFieldValue('phone', phone)}
                onClear={() => setFieldValue('phone', '')}
                error={
                  (errors.phone || errors.is_valid_phone) &&
                  (touched.phone || touched.is_valid_phone) &&
                  (errors.phone || errors.is_valid_phone)
                }
                isValid={values.is_valid_phone}
                formikBag={{ setFieldValue, touched }}
                containerID="phone-input"
                className="profile-personal-form__field"
              />
              <InputTextField
                label="Residence city *"
                name="city"
                value={values.city}
                onChange={handleChange}
                onClear={() => setFieldValue('city', '')}
                error={errors.city && touched.city && errors.city}
                className="profile-personal-form__field"
              />
              <InputTextField
                label="Residence address *"
                name="address"
                value={values.address}
                onChange={handleChange}
                onClear={() => setFieldValue('address', '')}
                error={errors.address && touched.address && errors.address}
                className="profile-personal-form__field"
              />
              <InputTextField
                label="Residence postal code"
                name="postal_code"
                value={values.postal_code}
                onChange={handleChange}
                onClear={() => setFieldValue('postal_code', '')}
                error={errors.postal_code && touched.postal_code && errors.postal_code}
                className="profile-personal-form__field"
              />
              <div className="profile-personal-form__group">
                <BasicUploadField
                  name="proof_of_residential_address"
                  label="Proof of founder’s residential address"
                  value={values.proof_of_residential_address}
                  acceptFormat={PDF_ONLY}
                  multiple={false}
                  isRequired
                  tooltipContent={() => (
                    <div style={{ maxWidth: '200px' }}>
                      Please provide a copy of your utility bill or personal bank account statement
                      that clearly indicates your residential address
                    </div>
                  )}
                  error={
                    errors.proof_of_residential_address &&
                    touched.proof_of_residential_address &&
                    errors.proof_of_residential_address
                  }
                  onError={message => setFieldError('proof_of_residential_address', message)}
                  className="profile-startup-form__company_registry"
                  onRemove={() => setFieldValue('proof_of_residential_address', [])}
                  onChange={files => setFieldValue('proof_of_residential_address', files)}
                />
              </div>
            </div>

            <div className="profile-personal-form__buttons">
              {onCancel && (
                <RoundButton
                  label="Cancel"
                  type="button"
                  fullWidth
                  onClick={onCancel}
                  className="profile-personal-form__button"
                />
              )}
              <RoundButton
                label="Save"
                type="submit"
                onSubmit={handleSubmit}
                fillBackground
                fullWidth
                disabled={!!Object.keys(errors).length}
                className="profile-personal-form__button"
              />
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

export default ProfileContactsForm;
