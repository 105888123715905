import React from 'react';
import { useLocation } from 'react-router-dom';
import logoWhite from '../../assets/images/logo_white.svg';
import { MyLink } from '../MyLink';
import { APP_LINKS } from '../../helpers/links';
import { CONTACT_EMAIL } from '../../helpers/constants';
import { WhatsAppChatLink } from '../WhatsAppChatLink';
import { SocialButtonsGrey } from '../SocialButtonsGrey';
import './index.scss';

const Footer = () => {
  const location = useLocation();
  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <footer className="footer">
      <div
        className={
          (location && location.pathname === '/sign-in') ||
          location.pathname === '/sign-up' ||
          location.pathname === '/sign-up/fds' ||
          location.pathname === '/forgot-password'
            ? 'container'
            : 'container-inner'
        }
      >
        <div className="footer__inner">
          <nav>
            {(location && location.pathname === '/sign-in') ||
            location.pathname === '/sign-up/' ||
            location.pathname === '/sign-up/fds' ||
            location.pathname === '/forgot-password' ? (
              <ul>
                <li className="footer_logo">
                  <MyLink className="footer__logo" href={APP_LINKS.dashboard}>
                    <img src={logoWhite} alt="logo_white" />
                  </MyLink>
                </li>
              </ul>
            ) : null}
            <ul>
              <h4 className="f-20 f-500">Company</h4>
              <li>
                <MyLink href={APP_LINKS.founderPromoDomain} isExternal>
                  Home
                </MyLink>
              </li>
            </ul>
            <ul>
              <h4 className="f-20 f-500">Legal</h4>
              <li>
                <MyLink href={APP_LINKS.docPrivacyAndPolicy} isExternal>
                  Privacy Policy
                </MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.docTermsOfUse} isExternal>
                  Terms of Use
                </MyLink>
              </li>
              {/*<li>*/}
              {/*  <MyLink href={APP_LINKS.unsubordinatedFinancialAgreement} isExternal>*/}
              {/*    Financing Agreement*/}
              {/*  </MyLink>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <MyLink href={APP_LINKS.subordinatedFinancialAgreement} isExternal>*/}
              {/*    Subordinated Financing Agreement*/}
              {/*  </MyLink>*/}
              {/*</li>*/}
              <li>
                <MyLink href={APP_LINKS.suretyAgreement} isExternal>
                  Surety agreement
                </MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.docAnnexToUnSubordinatedLoansAgreement} isExternal>
                  Annex to Financing <br />
                  Agreement
                </MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.docAnnexToSubordinatedLoansAgreement} isExternal>
                  Annex to Subordinated <br />
                  Financing Agreement
                </MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.docLoanSeveranceAgreement} isExternal>
                  Loan Severance Agreement
                </MyLink>
              </li>
              <li>
                <MyLink href={APP_LINKS.docAssignmentAgreementGeneralTerms} isExternal>
                  Assignment Agreement
                </MyLink>
              </li>
            </ul>
            <ul>
              <h4 className="f-20 f-500">Help</h4>
              {/*<li>*/}
              {/*  <a href="/help">FAQ</a>*/}
              {/*</li>*/}
              <li>
                <MyLink href={APP_LINKS.helpLoanTerms()}>Loan terms</MyLink>
              </li>
            </ul>
            <ul>
              <h4 className="f-20 f-500">Support</h4>
              <p>Contact us:</p>
              <li>
                <WhatsAppChatLink />
              </li>
              <li>
                <MyLink href={`mailto:${CONTACT_EMAIL}`} isExternal>
                  {CONTACT_EMAIL}
                </MyLink>
              </li>
              <li>
                <SocialButtonsGrey className="footer__content-social" />
              </li>
            </ul>
          </nav>

          <hr />

          <div className="footer__content-copyright">
            <p>©{currentYear} Scramble OÜ. All rights reserved.</p>
            <p>Scramble OÜ (registry code: 14991448) Pärnu mnt 22, 10141 Tallinn, Estonia</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
