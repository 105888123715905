import React from 'react';
import { Formik } from 'formik';
import classnames from 'classnames';
import { MyLink } from '../../MyLink';
import { APP_LINKS } from '../../../helpers/links';
import { RoundButton } from '../../UI/RoundButton';
import WarningBlock from '../../UI/WarningBlock';
import Checkbox from '../../UI/Checkbox';
import './index.scss';

const CoFounderFundraisingAcceptForm = ({ status, className, disabled, onSubmit }) => {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        has_accepted_settings: status.is_confirmed,
        has_accepted_terms: status.is_confirmed || status.has_accepted_terms,
      }}
    >
      {({ values, touched, errors, setFieldValue, handleSubmit }) => (
        <form className={classnames('cofounder_fr_accept_form', className)} onSubmit={handleSubmit}>
          <Checkbox
            name="has_accepted_settings"
            value="has_accepted_settings"
            isChecked={values.has_accepted_settings}
            onChange={() => setFieldValue('has_accepted_settings', !values.has_accepted_settings)}
            className="cofounder_fr_accept_form__checkbox"
            disabled={disabled}
            render={() => (
              <div className="f-16 f-400">
                I have reviewed and agree with the fundraising settings for the current round.
              </div>
            )}
          />

          {status.is_resold_loans ? (
            <Checkbox
              name="has_accepted_terms"
              className="cofounder_fr_accept_form__checkbox"
              disabled={disabled}
              isChecked={values.has_accepted_terms}
              onChange={() => setFieldValue('has_accepted_terms', !values.has_accepted_terms)}
              error={touched.has_accepted_terms && errors.has_accepted_terms}
              render={() => (
                <span
                  className={classnames(
                    'f-16',
                    touched.has_accepted_terms && errors.has_accepted_terms && 'error'
                  )}
                >
                  I have read the terms and conditions of the{' '}
                  <MyLink href={APP_LINKS.docTermsOfUse} isExternal>
                    Terms of Use
                  </MyLink>
                  , the{' '}
                  <MyLink href={APP_LINKS.docLoanSeveranceAgreement} isExternal>
                    Severance Agreement
                  </MyLink>
                  , the{' '}
                  <MyLink href={APP_LINKS.docAnnexToUnSubordinatedLoansAgreement} isExternal>
                    Annex to Financing Agreement
                  </MyLink>
                  , the{' '}
                  <MyLink href={APP_LINKS.docAnnexToSubordinatedLoansAgreement} isExternal>
                    Annex to Subordinated Financing Agreement
                  </MyLink>
                  , the{' '}
                  <MyLink href={APP_LINKS.docAssignmentAgreementGeneralTerms} isExternal>
                    Assignment Agreement
                  </MyLink>
                  , the{' '}
                  <MyLink href={APP_LINKS.suretyAgreement} isExternal>
                    Surety Agreement
                  </MyLink>
                  , and I agree to conclude them with the Investors as a result of the current
                  fundraising round.
                </span>
              )}
            />
          ) : (
            <Checkbox
              name="has_accepted_terms"
              disabled={disabled}
              isChecked={values.has_accepted_terms}
              className="cofounder_fr_accept_form__checkbox"
              onChange={() => setFieldValue('has_accepted_terms', !values.has_accepted_terms)}
              error={touched.has_accepted_terms && errors.has_accepted_terms}
              render={() => (
                <span
                  className={classnames(
                    'f-16',
                    touched.has_accepted_terms && errors.has_accepted_terms && 'error'
                  )}
                >
                  I have read the terms and conditions of the{' '}
                  <MyLink href={APP_LINKS.docTermsOfUse} isExternal>
                    Terms of Use
                  </MyLink>
                  , the{' '}
                  <MyLink href={APP_LINKS.unsubordinatedFinancialAgreement} isExternal>
                    Financing Agreement
                  </MyLink>
                  , the{' '}
                  <MyLink href={APP_LINKS.subordinatedFinancialAgreement} isExternal>
                    Subordinated Financing Agreement
                  </MyLink>
                  , the{' '}
                  <MyLink href={APP_LINKS.suretyAgreement} isExternal>
                    Surety Agreement
                  </MyLink>
                  , and I agree to conclude them with the Investors as a result of the current
                  Fundraising round.
                </span>
              )}
            />
          )}

          <div className="cofounder_fr_accept_form__submit">
            {disabled && (
              <WarningBlock className="cofounder_fr_accept_form__warning">
                <div className="f-16 f-500">Verify your identity to confirm fundraising.</div>
              </WarningBlock>
            )}
            <RoundButton
              label="Save confirmation"
              type="button"
              fillBackground
              fullWidth
              disabled={disabled || !(values.has_accepted_settings && values.has_accepted_terms)}
              onClick={handleSubmit}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};

export default CoFounderFundraisingAcceptForm;
